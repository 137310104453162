<div class="modal-overlay" (click)="close()">
  <div class="modal-content" (click)="$event.stopPropagation()">
    <p class="fs-1 head poppins-bold text-center">
      GET THE <span class="clr">NEVLY MONEY</span> APP
    </p>
    <p class="text-center poppins-medium scantext m-0">
      Scan the QR code to download the app
    </p>
    <div class="text-center">
      <img
        src="images/qrcode.png"
        class="img-fluid"
        alt="QR Code"
        height="200px"
        width="200px"
      />
      <p class="poppins-medium scantext m-0">Or get a download link via SMS</p>
    </div>
    <div class="row justify-content-center mt-3">
      <!-- Flag and Country Code -->
      <div class="col-3 col-md-3 mb-3 mb-md-0 px-0 flag-div">
        <div class="flag">
          <img src="images/flag.png" class="img-fluid" alt="Flag" width="32" />
          &nbsp;+1
        </div>
      </div>

      <!-- Phone Number Input -->
      <div class="col-6 col-md-6 px-0">
        <div class="verification-input d-flex">
          <input
            type="text"
            maxlength="6"
            placeholder="Mobile number"
            class="form-control code-input flex-grow-1"
          />
          <fa-icon class="iconbg ms-2" [icon]="faArrowRight"></fa-icon>
        </div>
      </div>
    </div>
  </div>
</div>
