import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { PopupComponent } from '../popup/popup.component';
import { faLock, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { LoginService } from '../services/login/login.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  @ViewChild('popupContainer', { read: ViewContainerRef })
  private popupContainer!: ViewContainerRef;

  loginUsingEmail: boolean = false;
  email: string | null = null;
  phone: string | null = null;
  showVerificationModal: boolean = false;

  constructor(
    private loginService: LoginService,
    private toastr: ToastrService,
    library: FaIconLibrary
  ) {
    library.addIcons(faLock, faInfoCircle);
  }

  // Toggles between email and phone login methods
  toggleLoginMethod(): void {
    this.loginUsingEmail = !this.loginUsingEmail;
    this.resetLoginFields();
  }

  // Resets the login input fields based on the selected login method
  private resetLoginFields(): void {
    this.email = null;
    this.phone = null;
  }

  // Handles login submission
  onContinue(): void {
    if (this.isFormInvalid()) {
      alert(
        this.loginUsingEmail
          ? 'Please enter an email address.'
          : 'Please enter a phone number.'
      );
      return;
    }

    this.loginService.otpLogin(this.buildLoginPayload()).subscribe({
      next: () => this.onLoginSuccess(),
      error: ({ error }) => this.onLoginError(error),
    });
  }

  // Checks whether the form input is invalid
  private isFormInvalid(): boolean {
    return (
      (this.loginUsingEmail && !this.email) ||
      (!this.loginUsingEmail && !this.phone)
    );
  }

  // Builds the payload for login request
  private buildLoginPayload(): { email?: string; phone?: string } {
    return {
      ...(this.email ? { email: this.email } : {}),
      ...(this.phone ? { phone: this.phone } : {}),
    };
  }

  // Handles successful login response
  private onLoginSuccess(): void {
    this.showVerificationModal = true;
  }

  // Handles login error response
  private onLoginError(error: any): void {
    this.toastr.error(
      error?.data?.message ?? 'Unexpected error occurred',
      'Error',
      {
        timeOut: 5000,
        progressBar: true,
        progressAnimation: 'increasing',
        closeButton: true,
        positionClass: 'toast-top-right',
      }
    );
  }

  // Closes the verification modal
  onCloseVerificationModal(): void {
    this.showVerificationModal = false;
  }

  // Opens a popup and cleans up previous popups
  openPopup(): void {
    this.popupContainer.clear(); // Clear any existing popup instances
    const popupRef = this.popupContainer.createComponent(PopupComponent);
    popupRef.instance.closePopup.subscribe(() => {
      popupRef.destroy(); // Destroy the popup when closing
    });
  }
}
