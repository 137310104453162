<div class="container-fluid p-0 overflow-hidden contact py-md-5 px-md-5 ">
<app-navbar></app-navbar>
<div class="my-5 text-center px-sm-5">
    <div>
      <p class="contact-head poppins-bold ">WE'RE HERE TO HELP YOU, 24/7</p>
      <p class=" poppins-regular contacttxt" style="color: white;">Whether you want to browse our FAQ for solutions or speak to us directly via in-app chat, we’ve got you.</p>
    </div>
  </div>
</div>
<div class="container-fluid px-0 py-3 overflow-hidden">
<div class="row">
    <div class="col-md-3 d-sm-none d-md-block" style="width: 28%;" >
        <img src="images/contact1.jpeg" class="img-fluid contact1" alt="">
    </div>
    <div class="d-sm-block d-md-none px-5">
        <img src="images/contact3.png" class="img-fluid contact3" alt="">
    </div>
    <div class="col-md-6 px-sm-5 section1" style="width: 44%; padding-top: 10%;">
        <p class="text-class poppins-bold text-dark  mb-3"><span class="clr">CHAT</span> WITH US DIRECTLY</p>
        <p class="desc poppins-regular mb-4">Speak to a member of our support team via secure in-app  chat.</p>
        <div class="text-center">
            <button class="btn btnclr py-3 px-3" routerLink="/faqs">VISIT IN-APP CHAT SUPPORT
            <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.7456 16.1524C8.50873 16.1524 8.31287 16.075 8.15799 15.9201C8.00313 15.7653 7.9257 15.5695 7.9257 15.3325C7.9257 15.0957 8.00313 14.8998 8.15799 14.745C8.31287 14.59 8.50873 14.5126 8.7456 14.5126H10.1121C9.96633 11.7979 9.00979 9.48849 7.24244 7.5845C5.4751 5.68052 3.33427 4.62831 0.819899 4.42789C0.583046 4.40967 0.387173 4.32312 0.23231 4.16825C0.0774476 4.01338 0 3.81753 0 3.58066C0 3.34381 0.0819898 3.14793 0.24597 2.99307C0.409949 2.83819 0.601265 2.76988 0.819899 2.78809C3.11562 2.95207 5.16992 3.73554 6.98282 5.13847C8.79569 6.54141 10.1121 8.32695 10.932 10.4951C11.5333 8.83713 12.4397 7.32031 13.6513 5.9447C14.8629 4.56909 16.3251 3.41668 18.0377 2.48745H14.4849C14.248 2.48745 14.0522 2.41002 13.8973 2.25516C13.7424 2.10028 13.665 1.90442 13.665 1.66756C13.665 1.4307 13.7424 1.23485 13.8973 1.07997C14.0522 0.925104 14.248 0.847656 14.4849 0.847656H19.9509C20.1878 0.847656 20.3836 0.925104 20.5384 1.07997C20.6934 1.23485 20.7708 1.4307 20.7708 1.66756V7.13356C20.7708 7.37041 20.6934 7.56628 20.5384 7.72115C20.3836 7.87603 20.1878 7.95346 19.9509 7.95346C19.7141 7.95346 19.5181 7.87603 19.3633 7.72115C19.2084 7.56628 19.131 7.37041 19.131 7.13356V3.77197C16.9264 4.91983 15.1818 6.42297 13.8973 8.28142C12.6128 10.1398 11.8976 12.2169 11.7519 14.5126H13.1184C13.3552 14.5126 13.5511 14.59 13.706 14.745C13.8608 14.8998 13.9383 15.0957 13.9383 15.3325C13.9383 15.5695 13.8608 15.7653 13.706 15.9201C13.5511 16.075 13.3552 16.1524 13.1184 16.1524H8.7456Z" fill="#1E4C53"/>
                </svg>                
        </button>
        </div>
    </div>
    <div class="col-md-3 d-sm-none d-md-block" style="width: 28%;">
        <img src="images/contact2.jpeg" class="img-fluid contact2" alt="">
    </div>
    <div class="d-sm-block d-md-none px-5">
        <img src="images/contact4.png" class="img-fluid contact4" alt="">
    </div>
</div>
</div>
<div class="container-fluid p-0 overflow-hidden outersection2">
    <div class="row ">
        <div class="col-md-6 d-flex align-items-center ps-5 section2">
            <div>
                <p class="poppins-bold text-class text-dark">READY-TO-GO <span class="clr">ANSWERS</span></p>
                <p class="desc poppins-regular my-4">Find detailed responses to common questions in our FAQs.</p>
                <div class="btn-center">
                    <button class="btn btnclr px-3 py-3" routerLink="/faqs">VISIT OUR FAQS
                        <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.7456 16.1524C8.50873 16.1524 8.31287 16.075 8.15799 15.9201C8.00313 15.7653 7.9257 15.5695 7.9257 15.3325C7.9257 15.0957 8.00313 14.8998 8.15799 14.745C8.31287 14.59 8.50873 14.5126 8.7456 14.5126H10.1121C9.96633 11.7979 9.00979 9.48849 7.24244 7.5845C5.4751 5.68052 3.33427 4.62831 0.819899 4.42789C0.583046 4.40967 0.387173 4.32312 0.23231 4.16825C0.0774476 4.01338 0 3.81753 0 3.58066C0 3.34381 0.0819898 3.14793 0.24597 2.99307C0.409949 2.83819 0.601265 2.76988 0.819899 2.78809C3.11562 2.95207 5.16992 3.73554 6.98282 5.13847C8.79569 6.54141 10.1121 8.32695 10.932 10.4951C11.5333 8.83713 12.4397 7.32031 13.6513 5.9447C14.8629 4.56909 16.3251 3.41668 18.0377 2.48745H14.4849C14.248 2.48745 14.0522 2.41002 13.8973 2.25516C13.7424 2.10028 13.665 1.90442 13.665 1.66756C13.665 1.4307 13.7424 1.23485 13.8973 1.07997C14.0522 0.925104 14.248 0.847656 14.4849 0.847656H19.9509C20.1878 0.847656 20.3836 0.925104 20.5384 1.07997C20.6934 1.23485 20.7708 1.4307 20.7708 1.66756V7.13356C20.7708 7.37041 20.6934 7.56628 20.5384 7.72115C20.3836 7.87603 20.1878 7.95346 19.9509 7.95346C19.7141 7.95346 19.5181 7.87603 19.3633 7.72115C19.2084 7.56628 19.131 7.37041 19.131 7.13356V3.77197C16.9264 4.91983 15.1818 6.42297 13.8973 8.28142C12.6128 10.1398 11.8976 12.2169 11.7519 14.5126H13.1184C13.3552 14.5126 13.5511 14.59 13.706 14.745C13.8608 14.8998 13.9383 15.0957 13.9383 15.3325C13.9383 15.5695 13.8608 15.7653 13.706 15.9201C13.5511 16.075 13.3552 16.1524 13.1184 16.1524H8.7456Z" fill="#1E4C53"/>
                        </svg>                    
                    </button>
                </div>
            </div>
        </div>
        <div class="col-md-6 px-md-0 px-sm-5">
            <img src="images/home2.png" class="img-fluid contact5"  alt="">
        </div>
    </div>
</div>
<app-footer></app-footer>
