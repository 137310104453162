import { Component, AfterViewInit, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
})
export class HomeComponent implements OnInit, AfterViewInit {
  constructor(private el: ElementRef, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.fragment.subscribe((fragment) => {
      if (fragment) {
        this.scrollToSection(fragment);
      }
    });
  }

  ngAfterViewInit(): void {
    const boxes: HTMLElement[] = Array.from(
      this.el.nativeElement.querySelectorAll('.scroll-section')
    );

    // Animate each section
    let boxHeights = 0;
    boxes.forEach((box, i) => {
      const boxSize = box.getBoundingClientRect();
      const endPosition =
        i === boxes.length - 1
          ? `+=${boxSize.height * 2}`
          : `+=${boxSize.height}`;

      gsap.to(box, {
        y: -50,
        scale: 0.85 + (i / boxes.length) * 0.1,
        duration: 1.5,
        ease: 'none',
        scrollTrigger: {
          start: `top+=${boxHeights}px`,
          end: endPosition,
          scrub: true,
          pin: true,
          pinSpacing: false,
        },
      });

      boxHeights += boxSize.height;
    });

    this.route.fragment.subscribe((fragment) => {
      if (fragment) {
        setTimeout(() => this.scrollToSection(fragment), 0);
      }
    });
  }

  private scrollToSection(fragment: string): void {
    const element = document.getElementById(fragment);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}
