<div class="container-fluid p-0 pt-md-3 px-md-5 px-sm-3">
  <app-navbar></app-navbar>
</div>
<div class="px-md-5 maindiv">
  <app-future-building
    class="scroll-section future-building-section"
  ></app-future-building>
  <app-second-section
    class="scroll-section second-section"
  ></app-second-section>
  <app-credit-builder
    class="scroll-section credit-builder-section"
  ></app-credit-builder>
  <app-money-access
    class="scroll-section money-access-section"
  ></app-money-access>
  <app-credit-report
    class="scroll-section credit-report-section"
  ></app-credit-report>
</div>
<div class="container-fluid p-0">
  <app-services-menu></app-services-menu>
  <app-nevly-money></app-nevly-money>
  <app-money-services></app-money-services>
  <app-featured></app-featured>
  <app-palns-subscription></app-palns-subscription>
  <app-footer></app-footer>
</div>
