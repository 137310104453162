<div
  class="container-fluid p-0 overflow-hidden"
  style="background-color: #ededed"
>
  <p class="feature poppins-bold text-center py-3">FEATURED ON</p>
  <div class="row pb-4 align-items-center justify-content-center gap-3 gap-md-0">
    <div class="col-4 col-md-2 px-md-4">
      <img src="images/featured4.png" class="img-fluid" alt="" />
    </div>
    <div class="col-4 col-md-2 px-md-4">
      <img src="images/featured.png" class="img-fluid" alt="" />
    </div>
    <div class="col-4 col-md-2 px-md-4">
      <img src="images/featured5.png" class="img-fluid" alt="" />
    </div>
    <div class="col-4 col-md-2 px-md-4">
      <img src="images/featured2.png" class="img-fluid" alt="" />
    </div>
    <div class="col-4 col-md-2 px-md-4">
      <img src="images/featured3.png" class="img-fluid" alt="" />
    </div>
  </div>
</div>
