import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment.development';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  otpLogin(payload: { phone?: string; email?: string }): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/users/auth/otp-login`, payload);
  }

  verifyPhoneOtp(payload: any): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/users/auth/verify-phone-otp`,
      payload
    );
  }

  verifyEmailOtp(payload: any): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/users/auth/verify-email-otp`,
      payload
    );
  }
}
