<footer class="footrmain" style="overflow-x: hidden">
  <div class="container-fluid ps-md-5 pt-3 pt-lg-5 ">
    <div class="row">
      <div class="col-12 col-md-3 px-4">
        <ul class="footer-links">
          <p class="title mb-4">Nevly</p>
          <li *ngFor="let item of footerItems">
            <a
              *ngIf="item.link"
              [routerLink]="item.link"
              class="footer-item poppins-regular"
              >{{ item.name }}</a
            >
          </li>
        </ul>
      </div>
      <div class="col-12 col-md-3 footer-links px-4">
        <p class="title mb-4">Nevly Money</p>

        <ul class="footer-links">
          <li *ngFor="let button of buttons">
            <a
              href="javascript:void(0)"
              class="footer-item poppins-regular"
              (click)="openModal(button.content)"
            >
              {{ button.label }}
            </a>
          </li>
        </ul>
      </div>
      <div class="col-12 col-md-3 px-4">
        <ul class="footer-links">
          <p class="title mb-4">TRANSPARENCY</p>
          <li *ngFor="let item of footerItem2">
            <a
              *ngIf="item.link"
              (click)="openPopup(item.name)"
              class="footer-item poppins-regular"
              >{{ item.name }}</a
            >
          </li>
        </ul>
      </div>
      <div class="col-12 col-md-3 px-4">
        <ul class="footer-links">
          <li *ngFor="let item of footerItem3">
            <p class="title mb-4">{{ item.title }}</p>
            <div>
              <button (click)="openJoinNowPopup()" class="store-button my-3">
                <img
                  src="images/appstore.png"
                  alt="Download on the App Store"
                />
              </button>
              <button (click)="openJoinNowPopup()" class="store-button">
                <img src="images/playstore.png" alt="Get it on Google Play" />
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row my-4">
    <div class="col-6 ps-5"><img src="images/footerlogo.png" alt="" /></div>
    <div class="col-6 text-end pe-5">
      <a href="https://www.tiktok.com/@nevlyapp" target="_blank"
        ><fa-icon class="icon px-3" [icon]="faTiktok"></fa-icon
      ></a>
      <a href="https://www.facebook.com/nevlyapp/" target="_blank"
        ><fa-icon class="icon px-3" [icon]="faFacebook"></fa-icon
      ></a>
      <a href="https://www.linkedin.com/company/nevly-app" target="_blank"
        ><fa-icon class="icon px-3" [icon]="faLinkedin"></fa-icon
      ></a>
      <a href="https://www.instagram.com/nevlybrand/" target="_blank"
        ><fa-icon class="icon px-3" [icon]="faInstagram"></fa-icon
      ></a>
    </div>
    <hr style="color: white" class="my-3" />
    <div class="copyright">© 2024 NEVLY Money. All rights reserved.</div>
  </div>
  <div
    class="modal mt-5 fade"
    id="privacyModal"
    tabindex="-1"
    aria-labelledby="privacyModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header fixed-header">
          <h5 class="modal-title" id="privacyModalLabel">{{ popupTitle }}</h5>
          <button
            type="button"
            class="btn-close"
            style="font-size: 25px; color: rgb(0, 0, 0)"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
        </div>
        <div class="modal-body">
          <div [innerHTML]="popupContent"></div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #popupContainer></ng-template>
</footer>
