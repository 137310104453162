import { Component } from '@angular/core';

@Component({
  selector: 'app-faqs-hero',
  templateUrl: './faqs-hero.component.html',
  styleUrls: ['./faqs-hero.component.css']
})
export class FaqsHeroComponent {
  searchTerm: string = '';  // Bind the search term to the input field

  // Method to trigger when search input changes
  onSearch() {
    // Emit the search term to the parent or another component
    // Here you can directly update or send this value
    this.filterFaqs(this.searchTerm);
  }

  // This will be passed or handled in another component, or for now you can call it directly
  filterFaqs(query: string) {
    // For example, if you have an FAQ list component, you could filter the list directly here
  }
}
