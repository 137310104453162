<div class="modal-overlay" (click)="closeModal()">
  <div class="modal-content" (click)="$event.stopPropagation()">
    <div class="verification-container">
      <h2 class="verification-title">Verification Code</h2>
      <p class="verification-message p-0 m-0">
        We have sent a verification code to
      </p>
      <p class="verification-message p-0 m-0 mb-4">
        
        {{ phoneNumber ?? email }}.
      </p>

      <div>
        <label class="label mb-2">Enter verification code</label>
        <div class="verification-input d-flex">
          <input
            type="text"
            maxlength="6"
            placeholder="Enter verification code"
            class="form-control code-input flex-grow-1"
            [(ngModel)]="code"
          />
          <fa-icon
            class="iconbg ms-2"
            [icon]="faArrowRight"
            (click)="verifyCode()"
          ></fa-icon>
        </div>
      </div>

      <p class="resend-message">
        Did not receive a code?
        <button class="btn btn-link p-0" (click)="resendCode()">
          Resend It
        </button>
      </p>
    </div>
  </div>
</div>
