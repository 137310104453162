<nav class="navbar navbar-expand-lg px-md-5 px-2 mb-4">
  <a class="navbar-brand" href="#">
    <img src="images/logo.png" alt="Logo" class="logo-img" />
  </a>
  <div class="d-flex justify-content-center align-items-center">
    <div
      class="d-lg-none d-md-flex btn-container d-flex justify-content-center align-items-center"
    >
      <button
        class="btn log-in-btn poppins-regular mx-2 me-2"
        routerLink="/login"
        type="button"
      >
        LOGIN
      </button>
      <button
        class="btn join-btn poppins-regular d-sm-none d-md-block"
        (click)="openPopup()"
        style="color: #70ee91"
        type="button"
      >
        JOIN NOW
        <svg
          width="14"
          height="10"
          viewBox="0 0 14 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.25326 9.40853C6.10881 9.40853 5.98937 9.36132 5.89492 9.26682C5.80048 9.17242 5.75326 9.05303 5.75326 8.90853C5.75326 8.76413 5.80048 8.64463 5.89492 8.55023C5.98937 8.45573 6.10881 8.40853 6.25326 8.40853H7.08659C6.9977 6.75303 6.41437 5.34465 5.33659 4.18354C4.25881 3.02243 2.95326 2.38076 1.41992 2.25854C1.27548 2.24743 1.15603 2.19465 1.06159 2.1002C0.967152 2.00576 0.919922 1.88632 0.919922 1.74187C0.919922 1.59743 0.969922 1.47798 1.06992 1.38354C1.16992 1.28909 1.28659 1.24743 1.41992 1.25854C2.81992 1.35854 4.0727 1.83632 5.17826 2.69187C6.28381 3.54743 7.08659 4.63631 7.58659 5.95854C7.95326 4.94743 8.50604 4.02243 9.24492 3.18354C9.98378 2.34465 10.8755 1.64187 11.9199 1.0752H9.75328C9.60878 1.0752 9.48937 1.02798 9.39492 0.933535C9.30048 0.839085 9.25326 0.719645 9.25326 0.575195C9.25326 0.430755 9.30048 0.311315 9.39492 0.216865C9.48937 0.122425 9.60878 0.0751953 9.75328 0.0751953H13.0866C13.2311 0.0751953 13.3505 0.122425 13.4449 0.216865C13.5394 0.311315 13.5866 0.430755 13.5866 0.575195V3.90854C13.5866 4.05298 13.5394 4.17243 13.4449 4.26687C13.3505 4.36132 13.2311 4.40854 13.0866 4.40854C12.9422 4.40854 12.8227 4.36132 12.7283 4.26687C12.6338 4.17243 12.5866 4.05298 12.5866 3.90854V1.85854C11.2422 2.55854 10.1783 3.4752 9.39492 4.60854C8.61159 5.74187 8.17548 7.00853 8.08659 8.40853H8.91992C9.06437 8.40853 9.18381 8.45573 9.27826 8.55023C9.3727 8.64463 9.41992 8.76413 9.41992 8.90853C9.41992 9.05303 9.3727 9.17242 9.27826 9.26682C9.18381 9.36132 9.06437 9.40853 8.91992 9.40853H6.25326Z"
            fill="#70EE91"
          />
        </svg>
      </button>
    </div>
    <button
      class="navbar-toggler"
      type="button"
      (click)="isNavbarCollapsed = !isNavbarCollapsed"
      aria-controls="navbarNav"
      aria-expanded="!isNavbarCollapsed"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
  </div>

  <div
    [ngbCollapse]="isNavbarCollapsed"
    class="collapse navbar-collapse"
    id="navbarNav"
  >
    <ul class="navbar-nav mx-auto">
      <li class="nav-item">
        <a
          class="nav-link poppins-regular"
          aria-current="page"
          (click)="scrollToSection('/', 'nevly-money')"
          >HOW NEVLY MONEY WORKS</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link poppins-regular"
          (click)="scrollToSection('/', 'nevly-services')"
          >SERVICES</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link poppins-regular"
          (click)="scrollToSection('/', 'plans-subscriptions')"
          >SUBSCRIPTION PLANS</a
        >
      </li>

      <li class="nav-item">
        <a class="nav-link poppins-regular" routerLink="/faqs">FAQs</a>
      </li>
    </ul>

    <div class="d-none d-lg-flex">
      <button
        class="btn log-in-btn poppins-regular mx-2 me-2"
        routerLink="/login"
        type="button"
      >
        LOGIN
      </button>

      <button
        class="btn join-btn poppins-regular mx-2"
        type="button"
        (click)="openPopup()"
      >
        JOIN NOW
        <svg
          width="14"
          height="10"
          viewBox="0 0 14 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.25326 9.40853C6.10881 9.40853 5.98937 9.36132 5.89492 9.26682C5.80048 9.17242 5.75326 9.05303 5.75326 8.90853C5.75326 8.76413 5.80048 8.64463 5.89492 8.55023C5.98937 8.45573 6.10881 8.40853 6.25326 8.40853H7.08659C6.9977 6.75303 6.41437 5.34465 5.33659 4.18354C4.25881 3.02243 2.95326 2.38076 1.41992 2.25854C1.27548 2.24743 1.15603 2.19465 1.06159 2.1002C0.967152 2.00576 0.919922 1.88632 0.919922 1.74187C0.919922 1.59743 0.969922 1.47798 1.06992 1.38354C1.16992 1.28909 1.28659 1.24743 1.41992 1.25854C2.81992 1.35854 4.0727 1.83632 5.17826 2.69187C6.28381 3.54743 7.08659 4.63631 7.58659 5.95854C7.95326 4.94743 8.50604 4.02243 9.24492 3.18354C9.98378 2.34465 10.8755 1.64187 11.9199 1.0752H9.75328C9.60878 1.0752 9.48937 1.02798 9.39492 0.933535C9.30048 0.839085 9.25326 0.719645 9.25326 0.575195C9.25326 0.430755 9.30048 0.311315 9.39492 0.216865C9.48937 0.122425 9.60878 0.0751953 9.75328 0.0751953H13.0866C13.2311 0.0751953 13.3505 0.122425 13.4449 0.216865C13.5394 0.311315 13.5866 0.430755 13.5866 0.575195V3.90854C13.5866 4.05298 13.5394 4.17243 13.4449 4.26687C13.3505 4.36132 13.2311 4.40854 13.0866 4.40854C12.9422 4.40854 12.8227 4.36132 12.7283 4.26687C12.6338 4.17243 12.5866 4.05298 12.5866 3.90854V1.85854C11.2422 2.55854 10.1783 3.4752 9.39492 4.60854C8.61159 5.74187 8.17548 7.00853 8.08659 8.40853H8.91992C9.06437 8.40853 9.18381 8.45573 9.27826 8.55023C9.3727 8.64463 9.41992 8.76413 9.41992 8.90853C9.41992 9.05303 9.3727 9.17242 9.27826 9.26682C9.18381 9.36132 9.06437 9.40853 8.91992 9.40853H6.25326Z"
            fill="#70EE91"
          />
        </svg>
      </button>
    </div>
  </div>
</nav>
<ng-template #popupContainer></ng-template>
