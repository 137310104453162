import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css'],
})
export class PopupComponent implements OnInit {
  faArrowRight = faArrowRight;
  faTimes = faTimes;

  @Output() closePopup = new EventEmitter<void>();
  countryCode: string = '+1';
  mobileNumber: string = '';

  ngOnInit(): void {
    // const inputElement = document.getElementById('phone') as HTMLInputElement;
    // if (inputElement) {
    //   intlTelInput(inputElement, {
    //     initialCountry: 'US',
    //     separateDialCode: true,
    //     utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.0.0/js/utils.js'
    //   });
    // }
  }

  close() {
    this.closePopup.emit();
  }

  onSubmit() {
    if (!this.mobileNumber) {
      return;
    }

    const fullNumber = `${this.countryCode}${this.mobileNumber}`;
    // Add logic to send SMS
  }
}
