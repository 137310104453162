<div style="background-color: #003333" class="faq-hero py-md-5 px-md-3 mb-4">
  <app-navbar></app-navbar>
  <div class="container">
    <div class="row my-5">
      <div class="col-12 col-md-7">
        <p class="header poppins-bold text-white">TOP QUESTIONS ABOUT US</p>
        <div class="d-flex" style="width: 100%">
          <div class="input-group" style="width: 100%">
            <input
              type="search"
              class="px-4 py-2 search"
              placeholder="Search "
              (input)="onSearch($event)"
            />
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <p class="desc poppins-regular">
          Need something cleared up? Here are our most frequently asked
          questions.
        </p>
      </div>
    </div>
  </div>
</div>

<ng-container *ngFor="let faq of filteredFaqData; let i = index">
  <ng-container *ngIf="faq.data && faq.data.length > 0">
    <div class="container main my-0 p-0">
      <div class="row sectionspace justify-content-between">
        <div class="col-md-6">
          <p class="poppins-semibold supportsection">{{ faq.title }}</p>
          <p class="faqhead poppins-extrabold">{{ faq.subTitle }}:</p>
          <p class="poppins-medium faqtext">{{ faq.description }}</p>
        </div>
        <div class="col-md-6">
          <div class="accordion">
            <div
              class="accordion-item"
              *ngFor="let item of faq.data; let subI = index"
              [ngStyle]="{
                'background-color': item.isOpen ? '#F9FAFB' : '#fff'
              }"
            >
              <div
                class="accordion-header poppins-bold"
                (click)="toggleAccordion(i, subI)"
              >
                {{ item.question }}
                <span class="accordion-icon" [class.open]="item.isOpen">
                  {{ item.isOpen ? "-" : "+" }}
                </span>
              </div>
              <div
                class="accordion-content poppins-regular"
                [class.open]="item.isOpen"
              >
                {{ item.content }}
                <ul *ngIf="item.list">
                  <li *ngFor="let listItem of item.list">{{ listItem }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<!-- Contact Section -->
<div class="container-fluid px-5 py-4 mb-5">
  <div class="py-5 rounded-5" style="background-color: #1e4c53">
    <p class="head poppins-bold text-center text-white">
      Still have questions?
    </p>
    <p class="txt text-center poppins-regular text-white my-4 pt-2">
      If there are still questions that our FAQs haven't answered, reach out to
      us and we ensure <br />
      that you get the information you need.
    </p>
    <div class="text-center">
      <button class="btnstyle py-3" routerLink="/contact">
        CONTACT SUPPORT
      </button>
    </div>
  </div>
</div>

<!-- Footer Component -->
<app-footer></app-footer>
