<div class="container-fluid maindiv px-0">
<div class=" px-0">
   <app-navbar class="navbar-hero"></app-navbar>
</div>
<div class="container-fluid p-0 overflow-hidden">
    <div class="row">
        <div class="col-md-6 loginpadding  my-md-5">
            <p class="loginhead poppins-bold mt-5">Welcome back</p>
            <p class="login-text poppins-regular">Enter the phone number associated with your account</p>
            <form action="">
                <div class="row">
                    <div class="col-3 mx-2 flag-div text-center d-flex justify-content-center align-items-center">
                        <div class="flag">
                          <img src="images/flag.png" class="img-fluid" alt="" width="32"> &nbsp;
                          +1
                        </div>
                      </div>
                      <!-- Phone Number Input -->
                      <div class="col-8 px-0">
                        <div class="input-group input-group-sm  mx-0">
                          <input type="text" class="form-control mob" placeholder="Mobile No"  aria-describedby="inputGroup-sizing-sm">
                        </div>
                      </div>
                </div>
                <p class="py-3 ">Lost access to my  phone</p>
                <button class="btn cbtn poppins-regular text-white py-3">Continue</button>
                <div class="text-divider my-3">
                    <span>or</span>
                </div>
                
                <button class="btn cbtn2 poppins-regular  py-3 my-2" routerLink="/login">Continue with
                    email</button>
                <button class="btn cbtn2 poppins-regular  py-3 my-2"><svg width="25" height="24" viewBox="0 0 25 24"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1_10771)">
                            <path
                                d="M12.1504 5.50006C13.6777 5.49913 15.156 6.03883 16.3234 7.02356L19.5934 3.90956C18.3381 2.75211 16.8303 1.90316 15.1897 1.43009C13.5491 0.957025 11.8208 0.872862 10.142 1.18428C8.46318 1.4957 6.88006 2.19413 5.51826 3.22415C4.15647 4.25416 3.05351 5.5874 2.29688 7.11806L5.98338 9.95956C6.41331 8.6631 7.24025 7.53478 8.34708 6.73441C9.4539 5.93404 10.7845 5.5022 12.1504 5.50006Z"
                                fill="#D94F3D" />
                            <path
                                d="M5.65039 12.0002C5.65131 11.3065 5.76374 10.6176 5.98339 9.95966L2.29689 7.11816C1.54281 8.63511 1.15039 10.3061 1.15039 12.0002C1.15039 13.6942 1.54281 15.3652 2.29689 16.8822L5.98339 14.0407C5.76374 13.3827 5.65131 12.6938 5.65039 12.0002Z"
                                fill="#F2C042" />
                            <path
                                d="M22.7012 10H12.2012V14.5H18.1512C17.7973 15.7724 16.9864 16.8694 15.8737 17.581L19.5318 20.401C21.8693 18.303 23.2427 14.8915 22.7012 10Z"
                                fill="#5085ED" />
                            <path
                                d="M15.8714 17.5805C14.7409 18.2276 13.4521 18.5459 12.1504 18.4995C10.7845 18.4974 9.4539 18.0655 8.34708 17.2652C7.24025 16.4648 6.41331 15.3365 5.98338 14.04L2.29688 16.8815C3.20745 18.7171 4.6121 20.2622 6.35285 21.343C8.09359 22.4238 10.1014 22.9975 12.1504 22.9995C14.8466 23.0728 17.4747 22.1468 19.5294 20.3995L15.8714 17.5805Z"
                                fill="#57A75C" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1_10771">
                                <rect width="24" height="24" fill="white" transform="translate(0.150391)" />
                            </clipPath>
                        </defs>
                    </svg>
                    Continue with Google</button>
                <button class="btn cbtn2 poppins-regular  py-3 my-2">
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_1_10783" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0"
                            width="25" height="24">
                            <g clip-path="url(#clip0_1_10783)">
                                <path
                                    d="M12.8054 4.93779C12.247 5.56748 11.7478 6.60144 11.8764 7.57142C12.8621 7.64341 13.8726 7.09225 14.5037 6.37313C15.1084 5.64092 15.5238 4.64694 15.408 3.6377C14.5333 3.67551 13.4365 4.22303 12.8054 4.93779ZM12.2662 8.21493C11.5577 8.21493 10.4888 7.442 9.33613 7.46381C7.85431 7.48562 6.46756 8.316 5.70628 9.60227C4.13659 12.2265 5.30767 16.0824 6.81105 18.2027C7.56434 19.2425 8.44304 20.4008 9.59335 20.3608C10.7197 20.3164 11.1399 19.6678 12.4979 19.6678C13.8447 19.6678 14.2393 20.3608 15.412 20.3346C16.6198 20.3164 17.3811 19.2912 18.1072 18.2434C18.9771 17.0531 19.3262 15.8803 19.3398 15.8192C19.3126 15.8112 16.9945 14.9554 16.9705 12.3726C16.9505 10.2167 18.7982 9.16818 18.8829 9.11947C17.8364 7.64632 16.2148 7.44418 15.6444 7.42818C15.5494 7.41873 15.4535 7.41364 15.3601 7.41364C14.0995 7.41364 12.9117 8.21493 12.2662 8.21493Z"
                                    fill="black" />
                            </g>
                        </mask>
                        <g mask="url(#mask0_1_10783)">
                            <rect x="0.150391" width="24" height="24" fill="#191C1F" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1_10783">
                                <rect width="24" height="24" fill="white" transform="translate(0.150391)" />
                            </clipPath>
                        </defs>
                    </svg>
                    Continue with Apple</button>


                <!-- <app-loginpopup></app-loginpopup> -->


            </form>
            <button class="btn cbtn3 poppins-regular py-3 my-2" (click)="openPopup()">Create account</button>
        </div>
        <div class="col-md-6 text-center d-flex flex-column justify-content-center" style="background-color: #003333;">
            <div class="my-3"><img src="images/loginlogo.png" alt=""></div>
            <div class="my-3"><img src="images/qrcode.png" width="317" height="317" alt=""></div>
            <p class="qrhead poppins-medium text-white">Log in with QR code</p>
            <p class="qrhead2 poppins-regular">Scan this code with your phone</p>
            <p class="qrhead2 poppins-regular ">camera to log in instantly</p>
        </div>
        <app-footer></app-footer>

    </div>
</div>


<!-- Modal Content -->



<ng-template #popupContainer></ng-template>
</div>