<div class="continer-fluid p-0 main-div bordrad mb-4" style="background-color: #003333;">
    <div class="row align-items-center ">
        <div class="col-md-6 align-items-center px-5">
            <p class="homehead poppins-bold text-white">THE FUTURE OF <span class="clr">(RE)BUILDING</span> CREDIT</p>
            <p class="hometext poppins-regular text-white">Nevly Money provides a set of tools to accelerate
                (re)building your credit.</p>
            <div class="text-center">
                <button class="btn btnjoin" (click)="openPopup()">JOIN NOW
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.0055 19.2279C9.79318 19.2279 9.61762 19.1585 9.47879 19.0196C9.33997 18.8808 9.27056 18.7053 9.27056 18.4929C9.27056 18.2807 9.33997 18.105 9.47879 17.9663C9.61762 17.8274 9.79318 17.758 10.0055 17.758H11.2304C11.0998 15.3246 10.2423 13.2544 8.65809 11.5477C7.07386 9.84093 5.15483 8.89774 2.90097 8.71809C2.68865 8.70176 2.51307 8.62418 2.37426 8.48534C2.23544 8.34653 2.16602 8.17096 2.16602 7.95863C2.16602 7.74632 2.23951 7.57074 2.3865 7.43192C2.53349 7.29309 2.70499 7.23185 2.90097 7.24818C4.95883 7.39517 6.8003 8.09747 8.42536 9.35504C10.0504 10.6126 11.2304 12.2132 11.9654 14.1567C12.5043 12.6705 13.3169 11.3108 14.403 10.0777C15.489 8.84466 16.7997 7.81164 18.3349 6.97869H15.1502C14.9378 6.97869 14.7623 6.90928 14.6235 6.77047C14.4846 6.63163 14.4152 6.45607 14.4152 6.24374C14.4152 6.03143 14.4846 5.85586 14.6235 5.71703C14.7623 5.57821 14.9378 5.50879 15.1502 5.50879H20.0498C20.2622 5.50879 20.4377 5.57821 20.5765 5.71703C20.7154 5.85586 20.7848 6.03143 20.7848 6.24374V11.1434C20.7848 11.3557 20.7154 11.5313 20.5765 11.6701C20.4377 11.809 20.2622 11.8784 20.0498 11.8784C19.8376 11.8784 19.6619 11.809 19.5232 11.6701C19.3843 11.5313 19.3149 11.3557 19.3149 11.1434V8.13013C17.3387 9.15906 15.7749 10.5065 14.6235 12.1724C13.472 13.8382 12.831 15.7001 12.7003 17.758H13.9252C14.1376 17.758 14.3131 17.8274 14.452 17.9663C14.5908 18.105 14.6602 18.2807 14.6602 18.4929C14.6602 18.7053 14.5908 18.8808 14.452 19.0196C14.3131 19.1585 14.1376 19.2279 13.9252 19.2279H10.0055Z"
                            fill="#1E4C53" />
                    </svg>
                </button>
                <ng-template #popupContainer></ng-template>
            </div>
        </div>
        <div class="col-md-6  ">
            <img src="images/home-hro.png" class="img-fluid hero-img" alt="">
        </div>
    </div>
</div>

   